<script lang="ts">
  import { page } from '$app/stores';
  import { titleCase } from 'shared/src/utils/titleCase';
  export let title: string;
  export let titleHeader: string | undefined = undefined;
  export let description: string;
  export let hideTitle = false;
  export let largeImage = false;
  export let seoTitle = title;
  export let seoUrl: string | null = null;
  export let seoImageUrl: string | null = null;
  export let seoImageAlt: string | null = null;
  export let seoImageWidth: number | null = null;
  export let seoImageHeight: number | null = null;
  export let doNotAutoCapitalize = false;

  let klazz: string = '';
  export { klazz as class };

  $: capitalizedTitle = doNotAutoCapitalize ? title : titleCase(title);
  $: capitalizedTitleHeader = doNotAutoCapitalize
    ? titleHeader || title
    : titleCase(titleHeader || title);

  const facebookAppId = import.meta.env.VITE_FACEBOOK_APP_ID as string;
</script>

<svelte:head>
  <title>{capitalizedTitleHeader}</title>
  <meta name="description" content={description} />

  {#if largeImage}
    <meta name="twitter:card" content="summary_large_image" />
  {:else}
    <meta name="twitter:card" content="summary" />
  {/if}

  <meta name="twitter:site" content="@uTheorydotcom" />
  <meta name="twitter:title" content={seoTitle} />
  <meta name="twitter:description" content={description} />
  <meta name="twitter:creator" content="@uTheorydotcom" />
  <meta
    name="twitter:image"
    content={seoImageUrl || 'https://utheory.com/assets/img/utheory-facebook-large.png'}
  />
  <meta name="twitter:image:alt" content={seoImageAlt || 'uTheory.com logo'} />

  <meta property="og:title" content={seoTitle} />
  <meta property="og:type" content="website" />
  <meta property="og:url" content={seoUrl || $page.url.toString()} />
  <meta
    property="og:image"
    content={seoImageUrl || 'https://utheory.com/assets/img/utheory-facebook-large.png'}
  />
  <meta property="og:image:alt" content={seoImageAlt || 'uTheory.com logo'} />

  {#if seoImageHeight && seoImageWidth}
    <meta property="og:image:height" content={'' + seoImageHeight} />
    <meta property="og:image:width" content={'' + seoImageWidth} />
  {:else if !seoImageUrl}
    <meta property="og:image:height" content="1500" />
    <meta property="og:image:width" content="1500" />
  {/if}
  <meta property="og:description" content={description} />
  <meta property="fb:app_id" content={facebookAppId} />
</svelte:head>

{#if !hideTitle}
  <h1 class="hidden md:block margins h1 {klazz}">
    {capitalizedTitle}
  </h1>
  <h1 class="md:hidden block margins h2 text-blue {klazz}">
    {capitalizedTitle}
  </h1>
{/if}
