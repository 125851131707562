// Excluded words:
const excludedWords = [
  'a',
  'an',
  'the',
  'and',
  'but',
  'for',
  'at',
  'by',
  'in',
  'from',
  'to',
  'as',
  'at',
  'by',
  'for',
  'from',
  'in',
  'of',
  'than',
  'to',
  'up',
  'with',
];

const abbreviations = ['mr', 'mrs', 'ms', 'dr', 'jr', 'sr', 'm.d.', 'md'];

/**
 * Converts a string of text into book title capitalization format.
 * ```typescript
 *  titleCase("so long and thanks for all the fish");
 *  // "So Long and Thanks for All the Fish"
 * ```
 */
export function titleCase(text: string) {
  if (!text) return text;
  const words = text.split(/\s/);
  const spaces = text.match(/\s/g) || [];

  const capitalizedWords = words.map((word, index, allWords) => {
    const priorWord = allWords[index - 1];
    // If there's no prior word, we capitalize regardless:
    if (!priorWord) return capitalizeFirstLetter(word);

    // Is it excluded normally?
    const isExcluded = !!excludedWords.find(
      (exclude) => word.replace(/[.,;!?]/g, '').toLowerCase() === exclude
    );

    // Is it after an abbreviation? In which case we won't care about
    // sentence ending punctuation.
    const afterAbbreviation = abbreviations.find((abbrev) =>
      priorWord
        .replace(/[.,;!?]/g, '')
        .toLowerCase()
        .includes(abbrev)
    );

    const afterPunctuation = ['.', ',', '!', '?'].includes(priorWord.slice(-1)[0] || '');

    if (afterPunctuation && !afterAbbreviation) {
      return capitalizeFirstLetter(word);
    }

    if (isExcluded) {
      return word;
    }

    return capitalizeFirstLetter(word);
  });

  return capitalizedWords.reduce((sentence, word, index) => {
    const space = spaces[index] || '';
    return sentence + word + space;
  }, '');
}

export function decapitalizeFirstLetter(word: string) {
  const firstCharacter = word[0];
  if (!firstCharacter) return word;
  return firstCharacter.toLowerCase() + word.slice(1);
}

export function capitalizeFirstLetter(word: string) {
  const firstCharacter = word[0];
  if (!firstCharacter) return word;
  return firstCharacter.toUpperCase() + word.slice(1);
}
